<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Answers</h2>
      <router-link to="/answers/create">
        <v-btn
            depressed
            color="primary"
        >
          Create
        </v-btn>
      </router-link>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Description
          </th>
          <th class="text-left">
            Question
          </th>
          <th class="text-left">
            Index
          </th>
          <th class="text-left">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in items"
            :key="item._id"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td v-if="item.question"><router-link :to="`/questions/${item.question._id}`">{{ item.question.name }}</router-link></td>
          <td v-if="!item.question"></td>
          <td>{{ item.index }}</td>
          <td>
            <router-link :to="`/answers/${item._id}`">
              <v-btn
                  depressed
                  color="primary"
                  class="mr-2"
              >
                Edit
              </v-btn>
            </router-link>
            <v-btn
                depressed
                color="error"
                @click="deleteItem = item"
            >
              Delete
            </v-btn>
          </td>
        </tr>
        <tr
            v-if="!items || !items.length"
        >
          <td colspan="4" class="text-center">Nothing here</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center" v-if="totalPages > 1">
      <v-pagination
          v-model="currentPage"
          :length="totalPages"
          @input="getData"
      ></v-pagination>
    </div>
    <AreYouSure v-if="deleteItem" v-on:closeModal="closeModal" v-on:submitModal="submitModal" />
  </div>
</template>


<script>
  import AreYouSure from "../../components/modal/AreYouSure";

  export default {
    name: 'Answers',
    components: {
      AreYouSure
    },
    data() {
      return {
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 20,
        items: [],
        deleteItem: null
      }
    },
    watch: {

    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        this.getAnswers();
        this.getAnswersCount();
      },
      getAnswers() {
        let url = '/answers';

        url += '?_limit=' + this.itemsPerPage;
        url += '&_start=' + (this.currentPage - 1) * this.itemsPerPage;

        this.$axios.get(url).then(res => {
          this.items = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
      getAnswersCount() {
        let url = '/answers/count';

        this.$axios.get(url).then(res => {
          this.totalItems = res.data;
          this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)
        }).catch(e => {
          console.log(e)
        })
      },
      submitModal() {
        let url = '/answers/' + this.deleteItem._id;
        this.$axios.delete(url).then(res => {
          this.deleteItem = null;
          this.getData();
          this.$root.notify({color: 'success', text: 'Success deleted'})
        }).catch(e => {
          this.$root.notify({color: 'error', text: e.error ? e.error : "Unknown Error!"})
        })
      },
      closeModal() {
        this.deleteItem = null;
      }
    }
  }

</script>

